.activeFa {
  background: rgba(20, 216, 157, 0.16);
  color: #ff3093;
}
.inActiveFa {
  background: rgba(255, 112, 8, 0.16);
  color: #ff7008;
}
.ant-collapse-content {
  background: transparent !important;
  color: rgba(0, 0, 0, 0.85);
  border-top: 1px solid rgba(115, 126, 141, 0.16) !important;
}
.ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 30px 20px 26px 37px !important;
  user-select: none !important;
}
.ant-collapse-content-box {
  padding: 26px 37px 17px 37px !important;
}
.ant-collapse > .ant-collapse-item {
  font-family: 'SF Pro Text';
  border-radius: 20px !important;
  margin-bottom: 24px;
  background: rgba(255, 255, 255, 0.5);
  font-weight: bold !important;
  font-size: 20px !important;
  padding-bottom: 0 0 17px 0 !important;
  border-bottom: 1px solid rgba(115, 126, 141, 0.16) !important;
}

.ant-collapse-item-active > .ant-collapse-header {
  color: rgba(20, 216, 157, 1) !important;
}
.ant-collapse-arrow {
  right: 38px !important;
}
.ant-collapse-arrow > svg {
  width: 20px !important;
  height: 20px !important;
}
@media screen and (min-width: 375px) and (max-width: 768px) {
  .ant-collapse {
    padding: 10px !important;
  }
  .ant-collapse-arrow {
    right: 12px !important;
  }
  .ant-collapse-item > .ant-collapse-header {
    position: relative;
    padding: 20px 45px 20px 17px !important;
  }
  .ant-collapse-content-box {
    padding: 20px 17px !important;
    font-size: 12px;
  }
  .ant-collapse > .ant-collapse-item {
    font-family: 'SF Pro Text';
    border-radius: 20px !important;
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.5);
    font-weight: bold !important;
    font-size: 16px !important;
    padding-bottom: 0 0 17px 0 !important;
    border-bottom: 1px solid rgba(115, 126, 141, 0.16) !important;
  }
}
